<template>
  <section class="empty-result" v-if="products?.length == 0 && !loading && productsCount == 0 && (isHome || isCategoryPage)" @click="goHome()">
    <img src="../../assets/img/empty-result-picture-data.png" alt="keine Ergebnisse" />
    <div>
      <h3>{{ t("LBL_EMPTY_RESULT_TITLE") }}</h3>
      <p>{{ t("LBL_EMPTY_RESULT_TEXT") }}</p>
    </div>
  </section>
  <section v-else class="pt-2">
    <h2 v-if="isHome">{{ t("LBL_ALL_BONUSES") }}</h2>
    <div
      class="product-container grid lg:grid-flow-row md:grid-flow-row lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-3 xs:grid-cols-2 xxs:grid-cols-2 grid-flow-row-dense xxs:grid-flow-row xxs:auto-cols-max overflow-x-auto"
    >
      <cms-teaser
        class="cms-teasers__image cms-teasers__image--two teaser-11"
        v-if="products?.length > 10 && landingPage.teaserContent?.body && landingPage.teaserContent?.body[0]?.image[0]?.image"
        :type="`${landingPage.teaserContent?.body[0].type.toString()}`"
        :image="landingPage.teaserContent?.body[0].image"
        :teaserContent="`${landingPage.teaserContent?.body[0].content}`"
      ></cms-teaser>

      <span v-for="(product, index) in products" :key="product.id">
        <div class="product-container-wrapper border-dotted hover:shadow-lg transform hover:border-highlighted transition duration-540" :class="product.highlight_id == 'RED' ? 'red' : ''">
          <product :product="product" :index="index" :detailed="false"></product>
        </div>
      </span>
    </div>
    <div class="flex h-20 justify-center items-center" v-if="showPagination">
      <button
        v-if="products?.length > 0 && products?.length != productsCount"
        @click="loadMoreProducts"
        class="bg-red-light font-paybackButton text-light text-base font-medium py-2 px-4 border-gray hover:border-gray rounded"
      >
        Mehr anzeigen
      </button>
    </div>
  </section>
</template>

<script>
  import { useStore } from "vuex";
  import { provide, computed, ref } from "vue";
  import Product from "@/components/Product/Product";
  import CmsTeaser from "@/components/Cms/CmsTeaser.vue";
  import { useI18n } from "vue-i18n";
  import { useRouter } from "vue-router";

  export default {
    props: {
      coOrder: {
        type: Boolean,
        default: false,
      },
      showPagination: {
        type: Boolean,
        default: false,
      },
      isLandingPage: {
        type: Boolean,
        default: false,
      },
    },
    name: "List",
    components: {
      Product,
      CmsTeaser,
    },
    setup(props) {
      const store = useStore();
      const router = useRouter();
      const { t } = useI18n();
      const filters = ref({});
      if (!props.isLandingPage) {
        if (props.coOrder) {
          filters.value["filterCoOrderProducts"] = "yes";
          store.dispatch("catalog/setFilters", filters.value);
          store.dispatch("catalog/getProductsList");
        }
      }
      const goHome = () => {
        router.push("/");
      };
      const loadMoreProducts = () => {
        store.dispatch("catalog/getProductsList", "viewMore");
      };

      let loading = computed(() => {
        return store.getters["apiLoading/getLoading"];
      });
      const products = computed(() => {
        return store.getters["catalog/getProducts"];
      });

      const productsCount = computed(() => {
        return store.getters["catalog/getProductsCount"];
      });

      const landingPage = computed(() => {
        return store.getters["catalog/getLandingPage"];
      });

      provide("coOrder", props.coOrder);

      return {
        products,
        loadMoreProducts,
        productsCount,
        landingPage,
        loading,
        goHome,
        t,
      };
    },
    computed: {
      isHome() {
        return this.$route.name === "home" || this.$route.name === "default" || this.$route.name === "search";
      },
      isCategoryPage() {
        return this.$route.name === "products";
      },
    },
  };
</script>
<style lang="postcss" scoped>
  .empty-result {
    @apply bg-blue-lighter px-8 py-4 inline-flex flex-row-reverse my-8 cursor-pointer;
    div {
      @apply flex flex-col justify-center items-start;
      h3 {
        @apply mb-8;
        font-size: 2rem;
      }
    }
    @media (max-width: 767px) {
      @apply flex-col;
    }
  }
  h2 {
    @apply ml-2;
  }
  .coorder-products {
    margin: 0 0.5rem;
    & > section {
      @media (max-width: 767px) {
        padding-left: 0.8rem;
        padding-right: 0.8rem;
        width: 100%;
        max-width: 100vw;

        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
        .product-container-wrapper {
          width: 175px;
        }
      }
      .product-container {
        @apply grid-cols-3;

        @media (max-width: 767px) {
          display: inline-flex;
        }
      }
    }
  }

  .product-container-wrapper {
    @apply text-black  px-2 py-2 rounded-md m-2;
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.15)) drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.12));
    background-color: #fff;
    cursor: pointer;
    padding: 5px;
    border: none;
    &:hover {
      box-shadow: inset 0 0 0 5px #bfcbdd;
    }
    &.red {
      box-shadow: inset 0 0 0 2px #c1002b;
      &:hover {
        box-shadow: inset 0 0 0 5px #c1002b;
      }
    }
    @media screen and (max-width: 425px) {
      @apply m-1 p-0;
    }
  }

  .cms-teasers__image--two {
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    grid-column: 3/-1;
    grid-row: 3/4;
    justify-content: center;
    align-items: center;
    display: flex;
    @apply text-black  px-2 py-2;
    background-color: #fff;
    cursor: pointer;
    padding: 5px;
    border: none;
    &.teaser-11 {
      margin: 0.5rem;
      width: calc(100% - 1rem);
     
    }
     @media (max-width: 960px) {
      grid-column: 2/-1;
    }
    @media (max-width: 600px) {
      grid-column: 1/-1;
    }
    .teaser-11 {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
</style>
