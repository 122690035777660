<template>
  <section class="cms-teasers">
    <div class="cms-wrapper">
      <div class="cms-teasers__container  w-full" :class="`${type ? `teaser-type-${type}` : ''}`">
        <!-- Teaser Image -->
        <div class="cms-teasers__image cms-teasers__image--one teaser-image" v-for="(img, index) in image" :key="index">
          <!-- Always open teaser link in new tab -->
          <a :class="teaserClass" :href="(img.url.indexOf('://') > 0 || img.url.indexOf('//') === 0) ? `${img.url}` : `https://${img.url}`" target="_blank" :title="`${img.title}`">
            <picture>
              <source v-if="`${img.image}`" media="(min-width:600px)" :srcset="`${img.image}`" />
              <source v-if="`${img.imageMobile}`" media="(min-width:320px)" :srcset="`${img.imageMobile}`" />
              <app-image :alt="'TEASER ' + `${img.title}`" :src="`${img.image}`"></app-image>
            </picture>
          </a>
        </div>

        <!-- teaser text -->
        <div class="cms-teasers__text teaser-text pl-4" v-if="type === '2' || type === '3' || type === '4'">
          <div v-html="teaserContent"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import AppImage from "@/components/Image/Image";
  import constants from "@/helpers/constants";

  export default {
    name: "CmsTeaser",
    props: {
      type: String,
      image: Array,
      teaserContent: String,
      teaserClass: String
    },
    components: {
      AppImage
    }
  };
</script>
<style lang="postcss" scoped>
.cms-teasers {
  max-width: 940px;
  margin: 0 auto;
  padding: 10px 0;
}
.landing-section.landing-page .cms-teasers {
  max-width: 960px;
  
  

}
  .teaser-type {
    @apply grid grid-cols-4;
  }
  .teaser-type-0,
  .teaser-type-11 {
    @apply flex m-0 p-0 max-h-full h-auto max-w-full w-auto;
  }
  .teaser-type-11 img {
    @apply max-w-none;
  }
  .teaser-type-1 {
    @apply mx-auto my-0 max-w-full;
  }
  .teaser-type-2 {
    @apply grid-flow-row col-span-2 flex-row;
  }
  .bodyCmsTeaser .teaser-type {
    @apply block;
  }
  .teaser-11 .teaser-type {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    padding: 0;
  }
  .teaser-11 .teaser-type-11 img {
        max-width: 100%;
    height: auto;
    min-width: auto;
    width: 100%;

  }
  .teaser-type-4,
  .teaser-type-2,
  .teaser-type-3 {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .teaser-type-3 .teaser-text {
    display: grid;
    order: 1;
  }
  .teaser-type-3 .teaser-image {
    display: grid;
    order: 2;
  }
  .teaser-image {
    display: flex;
    align-items: center;
  }
  /* Added styles for header & footer teaser aspect ratio */
  /* - Image + text */ /* 2 images */ /* text + image */
  .teaser-type-4 .headerTeaser img,
  .teaser-type-2 .headerTeaser img,
  .teaser-type-3 .headerTeaser img,
  .teaser-type-4 .footerTeaser img,
  .teaser-type-2 .footerTeaser img,
  .teaser-type-3 .footerTeaser img {
    width: 470px;
  }

  /* BigImage  */
  .teaser-type-1 .headerTeaser img,
  .teaser-type-1 .footerTeaser img {
    width: 960px;
  }

  .headerTeaser img {
    height: 210px;
  }

  .footerTeaser img {
    height: auto;
  }
  @media screen and (min-device-width: 280px) and (max-device-width: 400px) {
    .cms-teasers {
      padding: 0!important;
    }
    .teaser-type-4 .headerTeaser img,
    .teaser-type-2 .headerTeaser img,
    .teaser-type-3 .headerTeaser img,
    .teaser-type-4 .footerTeaser img,
    .teaser-type-2 .footerTeaser img,
    .teaser-type-3 .footerTeaser img,
    .teaser-type-1 .headerTeaser img,
    .teaser-type-1 .footerTeaser img {
      max-width: 100%;
      width: 400px;
      height: auto;
      padding: 0 1rem;
    }
    .teaser-type-4,
    .teaser-type-2,
    .teaser-type-3 {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  @media screen and (min-device-width: 400px) and (max-device-width: 768px) {
    .cms-teasers {
      padding: 0!important;
    }
    .teaser-type-4 .headerTeaser img,
    .teaser-type-2 .headerTeaser img,
    .teaser-type-3 .headerTeaser img,
    .teaser-type-4 .footerTeaser img,
    .teaser-type-2 .footerTeaser img,
    .teaser-type-3 .footerTeaser img,
    .teaser-type-1 .headerTeaser img,
    .teaser-type-1 .footerTeaser img {
      max-width: 100%;
      width: 500px;
      height: auto;
      padding: 0 1rem;
    }

    .teaser-type-4,
    .teaser-type-2,
    .teaser-type-3 {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
</style>
