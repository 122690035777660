<template>
  <section class="landing-section" :class="{ 'landing-page': isLanding }">
    <nav-menu></nav-menu>
    <!-- CMS top -->
    <cms-teaser
      v-if="isLandingWithHeaderTeaser()"
      :type="`${landingPage.teaserContent?.header[0].type.toString()}`"
      :image="landingPage.teaserContent?.header[0].image"
      :teaserContent="`${landingPage.teaserContent?.header[0].content}`"
      teaserClass="headerTeaser"
    ></cms-teaser>

    <!-- Main Content Wrapper -->
    <div class="page-content-lightestblue" v-if="isLanding && landingPage?.landingPageContents?.length !== 0">
      <div class="page-content-lightestblue__container sm:p-8" :style="{ background: landingPage?.backgroundColor }">
        <div v-for="content in landingPage?.landingPageContents" :key="content">
          <h2 v-if="content?.productText" class="landing-page-heading py-4 m-0" :style="{ color: content.productTextColor }">
            <div v-html="content.productText"></div>
          </h2>
          <product-list :is-landing-page="true"></product-list>

          <!-- CTA Image -->
          <div class="flex justify-end items-end cta-praemien mt-8" v-if="landingPage?.ctaImage && landingPage?.ctaUrl">
            <a :href="`${landingPage.ctaUrl}`" :title="`${landingPage.ctaText}`" target="_blank">
              <app-image class="" :title="`${landingPage.ctaText}`" :alt="`${landingPage.ctaText}`" :src="`${landingPage.ctaImage}`"></app-image>
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- CMS Bottom -->
    <cms-teaser
      v-if="isLandingWithFooterTeaser()"
      :type="`${landingPage.teaserContent?.footer[0].type.toString()}`"
      :image="landingPage.teaserContent?.footer[0].image"
      :teaserContent="`${landingPage.teaserContent?.footer[0].content}`"
      teaserClass="footerTeaser"
    ></cms-teaser>

    <!-- Additional Information -->
    <div v-if="isLandingWithAdditionalContentORContentOnly()">
      <div v-for="content in landingPage.landingPageAdditionalContents" :key="content.title" class="additional-content-section">
        <div v-if="content.title || content.text" class="page-content-lightestblue">
          <div class="page-content-lightestblue__container border-gray-lighter p-8" :style="{ background: content.background_color }">
            <div
              class="additionContentTitle"
              :class="landingPage.type === 'landing_page' ? 'additionContentTitleLanding' : 'additionContentTitle'"
              @click="isLanding() && (content.expanded = !content.expanded)"
              :style="{ 'border-color': content.title_color }"
            >
              <div v-if="content.title">
                <h5 class="px-4" :style="{ color: content.title_color }" :class="isLanding() ? 'landing-heading' : 'content-heading'">
                  {{ content.title }}
                </h5>
              </div>
              <div v-if="isLanding() && content.title">
                <span :class="{ down: !content.expanded }" class="arrow up"></span>
              </div>
            </div>
            <div v-if="isLanding() ? content.expanded : 'true'" :class="isLanding() ? 'additionContentTextLanding' : 'additionContentText'">
              <div :style="{ color: content.text_color }" v-html="content.text"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-section" v-if="isLandingWithFooterImage()">
      <a :href="`${landingPage?.footerImageLink}`" target="_blank" :title="`${landingPage.footerImageText}`">
        <picture>
          <source v-if="`${landingPage.footerImageDesktop}`" media="(min-width:600px)" :srcset="`${landingPage.footerImageDesktop}`" />
          <source v-if="`${landingPage.footerImageMobile}`" media="(min-width:320px)" :srcset="`${landingPage.footerImageMobile}`" />
          <app-image :alt="`${landingPage.footerImageText}`" :src="`${landingPage.footerImageDesktop}`"></app-image>
        </picture>
      </a>
    </div>
  </section>
</template>

<script>
  import { computed } from "vue";
  import { useRoute } from "vue-router";
  import { useStore } from "vuex";
  import NavMenu from "@/components/Menu/NavMenu";

  import AppImage from "@/components/Image/Image";
  import Product from "@/components/Product/Product";
  import CmsTeaser from "../components/Cms/CmsTeaser.vue";
  import ProductList from "@/components/Product/List";

  export default {
    name: "LandingPage",
    components: {
      AppImage,
      Product,
      ProductList,
      CmsTeaser,
      NavMenu,
    },
    setup() {
      const store = useStore();
      const route = useRoute();

      store.dispatch("catalog/getLandingPage", route.params.url_key);
      const products = computed(() => {
        return store.getters["catalog/getProducts"];
      });
      const landingPage = computed(() => {
        return store.getters["catalog/getLandingPage"];
      });

      const isLandingWithHeaderTeaser = () => {
        return isLanding && landingPage.value?.teaserContent?.header && landingPage.value?.teaserContent?.header[0]?.image[0]?.image;
      };

      const isLandingWithFooterTeaser = () => {
        return isLanding && landingPage.value?.teaserContent?.footer && landingPage.value?.teaserContent?.footer[0]?.image[0]?.image;
      };

      const isLandingWithFooterImage = () => {
        return isLanding && (landingPage.value?.footerImageDesktop || landingPage.value?.footerImageMobile);
      };

      const isLandingWithAdditionalContentORContentOnly = () => {
        return landingPage.value?.type === "content_page" || (isLanding && isAdditionalWithTitle);
      };

      const isAdditionalWithTitle = () => {
        return landingPage.value?.landingPageAdditionalContents[0]?.title?.length > 0;
      };

      const isLanding = () => {
        return landingPage.value?.type === "landing_page";
      };

      return {
        products,
        landingPage,
        isLandingWithHeaderTeaser,
        isLandingWithFooterTeaser,
        isLandingWithAdditionalContentORContentOnly,
        isLandingWithFooterImage,
        isAdditionalWithTitle,
        isLanding,
      };
    },
  };
</script>
<style lang="postcss" scoped>
  .arrow {
    mask-image: url(../assets/img/icons/ic_arrow_drop_down.svg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    mask-size: 20px 20px;
    mask-repeat: no-repeat;
    mask-position: center;

    -webkit-mask-size: 20px 20px;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: center;
    height: 20px;
    width: 20px;
    @apply flex justify-center items-center;
  }

  .up {
    transform: rotate(-180deg);
    -webkit-transform: rotate(-180deg);
    transition: transform 0.3s;
    background: rgb(0, 70, 170);

    &.down {
      transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transition: transform 0.3s;
      background: rgb(0, 70, 170);
    }
  }
  .additionContentTitle {
    @apply flex flex-row justify-between items-center;
  }
  .additionContentTitleLanding {
    @apply py-3 border-b;
  }

  .product-container {
    @apply grid grid-cols-4 grid-flow-row-dense  items-center;
  }

  .bodyCmsTeaser .cms-teasers {
    @apply m-2 p-1 w-max-content;
  }
  .bodyCmsTeaser .teaser-type {
    @apply block;
  }

  .additionContentTextLanding,
  .additionContentText {
    padding: 1rem;
    line-height: 1.4rem;
    max-width: 100%;
    --text-opacity: 1;
    /* color: rgba(0, 0, 0, var(--text-opacity)); */
    font-size: 14px;
  }

  .pb-text a {
    text-decoration: underline !important;
  }
  .landing-heading,
  .content-heading {
    /* color: rgb(0, 70, 170); */
    font-size: 15px;
    font-weight: 400;
    line-height: 1rem;
  }
  .footer-section {
    display: flex;
    justify-content: center;
    padding: 1rem;
  }
  .footer-section img {
    height: auto;
    width: 95.7%;
    margin: 0 auto;
  }
  @media (min-width: 992px) {
    .footer-section img {
      height: auto;
      width: 960px;
    }
  }

  .cta-praemien img {
    width: 460px;
    height: 80px;
  }
  .landing-page-heading {
    font-size: 1.5rem;
    font-weight: 400;
    letter-spacing: 0.009375em;
    line-height: 2rem;
    --text-opacity: 1;
    /* color: #4b4b4d !important; */
    line-height: 2.5rem;
    font-family: PAYBACKLight, Open Sans, Arial, Helvetica Neue, Helvetica, sans-serif;
    font-weight: 400;
  }
</style>
